<template>
  <div class="base" style="width: 100%; height: 100%">
    <transition name="el-fade-in-linear">
      <div class="whole_wrap" v-show="show.wholeShow">
        <div class="head_wrap"></div>
        <div class="body_wrap">
          <div class="staff_content">
            <el-card shadow="always">
              <div class="staff_top_content">
                <el-alert
                  type="success"
                  :description="$t('staff.alert_staff_use_body')"
                  :closable="false"
                  show-icon
                  effect="dark"
                >
                </el-alert>
                <el-alert
                  type="warning"
                  :description="$t('staff.alert_staff_tip_body')"
                  :closable="false"
                  show-icon
                  effect="dark"
                >
                </el-alert>
                <span class="countStaff"
                  >{{ pageData.staffList.length
                  }}{{ $t("staff.span_countstaff") }}</span
                >
                <div class="top_btn_content">
                  <!-- <el-button
                    class="btn_import"
                    size="small"
                    type="danger"
                    @click="importStaff"
                    :disabled="true"
                    >{{ $t("common.btn_import") }}</el-button
                  > -->
                  <el-button
                    size="small"
                    type="primary"
                    @click="openStaffAdder"
                    >{{ $t("staff.btn_addstaff") }}</el-button
                  >
                  <el-button
                    size="small"
                    type="success"
                    @click="synFromDingtalk"
                    :loading="pageData.synDtLoad"
                    >{{ $t("staff.btn_synfromdt") }}</el-button
                  >
                </div>
                <el-dialog
                  width="50%"
                  :title="$t('staff.title_syn_dtuser')"
                  v-model="pageData.onjobAdderVisible"
                  ref="onjobAdder"
                  :modal="true"
                  :close-on-click-modal="false"
                  :close-on-press-escape="false"
                  :center="true"
                >
                  <div class="onjob_top_content">
                    <el-button
                      size="small"
                      type="success"
                      @click="importOnjob"
                      :loading="pageData.importOnjobLoad"
                      :disabled="pageData.onjobSelection.length == 0"
                      >{{ $t("staff.btn_importonjob") }}</el-button
                    >
                  </div>
                  <div class="onjob_table_content">
                    <el-table
                      stripe
                      border
                      ref="onjobTable"
                      @selection-change="onjobTableSelectionChange"
                      :header-cell-style="{
                        textAlign: 'center',
                        backgroundColor: '#f2f6fc',
                      }"
                      :cell-style="{ textAlign: 'center' }"
                      :data="pageData.onjobList"
                    >
                      <el-table-column type="selection"> </el-table-column>

                      <el-table-column type="index"> </el-table-column>

                      <el-table-column :label="$t('staff.label_dt_userid')">
                        <template #default="scope">
                          {{ scope.row.userid.substr(0, 8) + "*********" }}
                        </template>
                      </el-table-column>

                      <el-table-column :label="$t('staff.label_dt_name')">
                        <template #default="scope">
                          <span
                            v-if="scope.row.type_sort == 1"
                            style="color: #f56c6c"
                            >{{ scope.row.name }}</span
                          >
                          <span v-else>{{ scope.row.name }}</span>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </el-dialog>
                <el-dialog
                  width="30%"
                  :title="$t('staff.title_add_staff')"
                  v-model="pageData.staffAdderVisible"
                  ref="staffAdder"
                  :modal="true"
                  :close-on-click-modal="false"
                  :close-on-press-escape="false"
                  :center="true"
                >
                  <el-form
                    :model="formData.newStaff"
                    ref="newStaffForm"
                    class="newStaffForm"
                    size="mini"
                  >
                    <el-form-item :label="$t('staff.label_username')">
                      <el-input
                        v-model="formData.newStaff.username"
                        maxlength="15"
                        show-word-limit
                        size="medium"
                        :placeholder="$t('text.placeholder')"
                      ></el-input>
                    </el-form-item>

                    <el-form-item :label="$t('staff.label_password')">
                      <el-input
                        size="medium"
                        show-password
                        v-model="formData.newStaff.password"
                        :placeholder="$t('login.placehoder_password')"
                        maxlength="15"
                        show-word-limit
                      ></el-input>
                    </el-form-item>

                    <el-form-item :label="$t('staff.label_dept')">
                      <el-select
                        v-model="formData.newStaff.internaluser.dept_id"
                        filterable
                        size="small"
                        style="width: 100%"
                        :placeholder="$t('text.placeholder_select')"
                      >
                        <el-option
                          v-for="(item, index) in pageData.deptList"
                          :key="index"
                          :label="item.name_show"
                          :value="item.id"
                        ></el-option>
                      </el-select>
                    </el-form-item>

                    <el-form-item :label="$t('staff.label_name')">
                      <el-input
                        v-model="formData.newStaff.fullname"
                        maxlength="60"
                        show-word-limit
                        size="medium"
                        :placeholder="$t('text.placeholder')"
                      ></el-input>
                    </el-form-item>

                    <el-form-item :label="$t('staff.label_englishname')">
                      <el-input
                        v-model="formData.newStaff.englishname"
                        maxlength="60"
                        show-word-limit
                        size="medium"
                        :placeholder="$t('text.placeholder')"
                      ></el-input>
                    </el-form-item>

                    <el-form-item :label="$t('staff.label_sex')">
                      <el-select
                        v-model="formData.newStaff.sex"
                        filterable
                        size="small"
                        style="width: 100%"
                        :placeholder="$t('text.placeholder_select')"
                      >
                        <el-option
                          v-for="(item, index) in pageData.sexList"
                          :key="index"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </el-form-item>

                    <el-form-item :label="$t('staff.label_ismanager')">
                      <el-switch
                        v-model="formData.newStaff.internaluser.is_manager"
                        active-color="#13ce66"
                        inactive-color="#ff4949"
                      >
                      </el-switch>
                    </el-form-item>
                  </el-form>
                  <template #footer>
                    <span>
                      <el-button
                        class="addStaffBtn"
                        type="success"
                        size="medium"
                        @click="doAddStaff(formData.newStaff)"
                        :loading="pageData.doAddStaffLoading"
                        >{{ $t("staff.btn_save") }}</el-button
                      >
                    </span>
                  </template>
                </el-dialog>
              </div>
              <div class="staff_body_content">
                <el-table
                  stripe
                  border
                  ref="staffTable"
                  :header-cell-style="{
                    textAlign: 'center',
                    backgroundColor: '#f2f6fc',
                  }"
                  :cell-style="{ textAlign: 'center' }"
                  :data="pageData.staffList"
                >
                  <el-table-column :label="$t('staff.label_username')">
                    <template #default="scope">
                      {{ scope.row.username }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    :label="$t('staff.label_name')"
                    sortable
                    prop="fullname"
                  >
                    <template #default="scope">
                      <span v-if="pageData.lang == 'ch'">{{
                        scope.row.fullname
                      }}</span>

                      <span v-else-if="pageData.lang == 'en'">
                        {{ scope.row.englishname }}</span
                      >
                      <span v-else> {{ scope.row.englishname }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('staff.label_dept_name')">
                    <template #default="scope">
                      <span v-if="pageData.lang == 'ch'">{{
                        scope.row.dept_name
                      }}</span>

                      <span v-else-if="pageData.lang == 'en'">
                        {{ scope.row.dept_name_en }}</span
                      >
                      <span v-else> {{ scope.row.dept_name_en }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    :label="$t('staff.label_matchuser')"
                    sortable
                    prop="matchuser"
                  >
                    <template #default="scope">
                      <span v-if="scope.row.name != null">{{
                        scope.row.name
                      }}</span>
                      <span v-else style="color: #f56c6c">
                        {{ $t("text.not_available") }}</span
                      >
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('staff.label_options')">
                    <template #default="scope">
                      <el-button
                        circle
                        class="el-icon-edit"
                        type="primary"
                        size="small"
                        @click="openStaffReviser(scope.row)"
                      ></el-button>
                      <el-button
                        circle
                        class="el-icon-minus"
                        type="danger"
                        size="small"
                        v-if="false"
                        @click="openStaffDeleter(scope.row)"
                      ></el-button>
                    </template>
                  </el-table-column>
                </el-table>
                <el-dialog
                  width="20%"
                  :title="$t('staff.title_modify_staff')"
                  v-model="pageData.staffReviserVisible"
                  ref="staffReviser"
                  :modal="true"
                  :close-on-click-modal="false"
                  :close-on-press-escape="false"
                  :center="true"
                >
                  <el-form
                    :model="formData.modifyStaff"
                    ref="modifyStaffForm"
                    class="modifyStaffForm"
                    size="mini"
                  >
                    <el-form-item :label="$t('staff.label_name')">
                      <el-input
                        v-model="formData.modifyStaff.fullname"
                        size="small"
                        :disabled="true"
                      ></el-input>
                    </el-form-item>

                    <el-form-item :label="$t('staff.label_dept')">
                      <el-select
                        v-model="formData.modifyStaff.dept_id"
                        filterable
                        size="small"
                        style="width: 100%"
                        :placeholder="$t('text.placeholder_select')"
                      >
                        <el-option
                          v-for="(item, index) in pageData.deptList"
                          :key="index"
                          :label="item.name_show"
                          :value="item.id"
                        ></el-option>
                      </el-select>
                    </el-form-item>

                    <el-form-item :label="$t('staff.label_matchuser')">
                      <el-select
                        v-model="formData.modifyStaff.dingtalk_userid"
                        filterable
                        size="small"
                        style="width: 100%"
                        :placeholder="$t('text.placeholder_select')"
                      >
                        <el-option
                          v-for="(item, index) in pageData.localOnjobUserList"
                          :key="index"
                          :label="item.name"
                          :value="item.userid"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-form>
                  <template #footer>
                    <span>
                      <el-button
                        class="addStaffBtn"
                        type="success"
                        size="medium"
                        @click="doModifyStaff(formData.modifyStaff)"
                        :loading="pageData.doModifyStaffLoading"
                        >{{ $t("staff.btn_save") }}</el-button
                      >
                    </span>
                  </template>
                </el-dialog>
              </div>
            </el-card>
            <el-backtop target=".staff_content" :right="10"> </el-backtop>
          </div>
        </div>
        <div class="foot_wrap"></div>
      </div>
    </transition>
  </div>
</template>

<script>
import * as utilsjs from '@/assets/js/utils'
import * as mountederjs from '@/assets/js/mounteder'
export default {
  data() {
    return {
      show: {
        wholeShow: false
      },
      pageData: {
        username: '',
        userinfo: {},
        lang: '',
        deptList: [],
        staffList: [],
        sexList: [],
        doAddStaffLoading: false,
        doModifyStaffLoading: false,
        staffAdderVisible: false,
        onjobAdderVisible: false,
        staffReviserVisible: false,
        synDtLoad: false,
        importOnjobLoad: false,
        onjobList: [],
        onjobSelection: [],
        localOnjobUserList: []
      },
      formData: {
        newStaff: {
          username: '',
          password: '',
          fullname: '',
          englishname: '',
          sex: '',
          internaluser: {
            dept_id: '',
            is_manager: false,
          }
        },
        modifyStaff: {
          fullname: '',
          username: '',
          dept_id: '',
          dingtalk_userid: '',
        }
      }
    };
  },
  mounted() {
    // document.title = this.$t('title.');
    this.pageData.username = sessionStorage.getItem('username');
    this.pageData.userinfo = JSON.parse(sessionStorage.getItem(this.pageData.username));
    this.pageData.lang = sessionStorage.getItem('lang');
    if (mountederjs.evaluateM(this)) {
      this.initSexList();
      this.getDept();
      this.getOnjobUserLocal();
      setTimeout(() => { this.show.wholeShow = true }, 1);
    } else {
      this.$router.push({ name: 'Login' });
    }
  },
  methods: {
    initSexList() {
      let man = {};
      let woman = {};
      man.label = this.$t("text.sex_man");
      man.value = '1';
      woman.label = this.$t("text.sex_woman");
      woman.value = '0';
      this.pageData.sexList.push(man);
      this.pageData.sexList.push(woman);
    },
    getDept() {
      let params = {
        user: {}
      };
      params.user.username = this.pageData.username;

      this.$axios({
        method: 'post',
        url: this.$api + '/api/evaluate/queryDept',
        data: params
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {

            this.pageData.deptList = response.data.data;

            for (let dept of this.pageData.deptList) {
              if (sessionStorage.getItem('lang') == 'en') {
                dept.name_show = dept.name_en;
              } else if (sessionStorage.getItem('lang') == 'ch') {
                dept.name_show = dept.name;
              } else {
                //roll to english
                dept.name_show = dept.name_en;
              }
            }

            this.getStaff();

            return;
          } else if (response.data.code == '210') {
            this.$notify.warning({
              title: this.$t("notify.title_server"),
              message: this.$t('dept.notify_deptnull')
            });
            return;
          } else {
            //code = 700 and more
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    },
    openStaffAdder() {
      this.pageData.staffAdderVisible = true;
    },
    doAddStaff(staff) {
      this.pageData.doAddStaffLoading = true;

      for (let index in staff) {
        if (utilsjs.strIsEmpty(staff[index])) {
          this.pageData.doAddStaffLoading = false;
          this.$notify.error({
            title: this.$t("common.notity_title_message"),
            message: this.$t('staff.notify_incompletely')
          });
          return;
        } else {
          continue;
        }
      }

      if (staff.username.length < 3 ||
        staff.password.length < 3 ||
        staff.username.length > 15 ||
        staff.password.length > 15) {
        this.pageData.doAddStaffLoading = false;
        this.$notify.error({
          title: this.$t("common.notity_title_message"),
          message: this.$t('staff.notify_username_password_length')
        });
        return;
      }

      //set values
      staff.creator = this.pageData.username;
      staff.reviser = this.pageData.username;
      staff.internaluser.creator = this.pageData.username;
      staff.internaluser.reviser = this.pageData.username;

      if (staff.internaluser.is_manager === true) {
        staff.internaluser.is_manager = 1;
      } else {
        staff.internaluser.is_manager = 0;
      }

      //post
      this.postToAddStaff(staff);
    },
    postToAddStaff(staff) {
      this.$axios({
        method: 'post',
        url: this.$api + '/api/evaluate/createUser',
        data: staff
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {
            this.$ElMessage.success({
              message: this.$t("text.message_saved"),
              type: 'success'
            });

            this.pageData.doAddStaffLoading = false;
            this.pageData.staffAdderVisible = false;
            this.getStaff();

            // setTimeout(() => {
            //   location.reload();
            // }, 800)
            return;
          } else if (response.data.code == '290') {
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('staff.notify_userexist_err')
            });

            this.pageData.doAddStaffLoading = false;
            return;
          } else {
            //code = 700 and more
            this.pageData.doAddStaffLoading = false;
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.pageData.doAddStaffLoading = false;
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.pageData.doAddStaffLoading = false;
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    },
    importStaff() {
      this.$notify.error({
        title: this.$t("notify.title_notification"),
        message: this.$t('common.notify_import')
      });
    },
    synFromDingtalk() {
      this.$confirm(this.$t("staff.text_synfromdt_body"), this.$t("text.text_confirm_title"), {
        confirmButtonText: this.$t("common.btn_message_confirm"),
        cancelButtonText: this.$t("common.btn_message_cancel"),
        closeOnClickModal: false,
        closeOnPressEscape: false,
        type: 'warning'
      }).then(() => {
        this.pageData.synDtLoad = true;
        let dingtalkLoginRequest = {};
        dingtalkLoginRequest.index = process.env.VUE_APP_DINGTALK_TOKEN_INDEX;
        this.$axios({
          method: 'post',
          url: this.$api + '/api/party/queryOnJobFromDingtalk',
          data: dingtalkLoginRequest
        }).then((response) => {
          if (response.status == 200) {
            if (response.data.code == '200') {

              this.pageData.onjobList = response.data.data;

              //open table
              this.pageData.onjobAdderVisible = true;

              this.pageData.synDtLoad = false;

              return;
            } else if (response.data.code == '290') {
              this.pageData.synDtLoad = false;
              this.$notify.warning({
                title: this.$t("notify.title_server"),
                message: this.$t('notify.dt_token_outofdate')
              });
              return;
            } else if (response.data.code == '701') {
              this.pageData.synDtLoad = false;
              this.$notify.warning({
                title: this.$t("notify.title_server"),
                message: this.$t('notify.dt_token_outofdate')
              });
              return;
            } else {
              //code = 700 and more
              this.pageData.synDtLoad = false;
              this.$notify.error({
                title: this.$t("notify.title_server"),
                message: this.$t('notify.content_refused')
              });
              return;
            }
          } else {
            this.pageData.synDtLoad = false;
            this.$notify.error({
              title: this.$t('notify.title_server'),
              message: this.$t('notify.content_server') + response.status
            });
            return;
          }
        }).catch((error) => {
          this.$notify.error({
            title: this.$t("notify.title_server"),
            message: this.$t('notify.content_refused')
          });
          return;
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t("text.text_canceled")
        });
      });
    },
    onjobTableSelectionChange(val) {
      this.pageData.onjobSelection = val;
    },
    importOnjob() {
      this.$confirm(this.$t("staff.text_import_onjob_body"), this.$t("text.text_confirm_title"), {
        confirmButtonText: this.$t("common.btn_message_confirm"),
        cancelButtonText: this.$t("common.btn_message_cancel"),
        closeOnClickModal: false,
        closeOnPressEscape: false,
        type: 'warning'
      }).then(() => {
        this.pageData.importOnjobLoad = true;
        this.$axios({
          method: 'post',
          url: this.$api + '/api/ex/importOnjob',
          data: this.pageData.onjobSelection
        }).then((response) => {
          if (response.status == 200) {
            if (response.data.code == '200') {
              this.pageData.importOnjobLoad = false;

              this.$notify.success({
                title: this.$t("notify.title_server"),
                message: this.$t('staff.notify_import_onjob_success')
              });
              this.getOnjobUserLocal();
              return;
            } else if (response.data.code == '210') {
              this.pageData.importOnjobLoad = false;
              this.$notify.warning({
                title: this.$t("notify.title_server"),
                message: this.$t('staff.notify_import_nochange')
              });
              this.getOnjobUserLocal();
              return;
            } else if (response.data.code == '290') {
              this.pageData.importOnjobLoad = false;
              this.$notify.warning({
                title: this.$t("notify.title_server"),
                message: this.$t('staff.notify_import_warn')
              });
              this.getOnjobUserLocal();
              return;
            } else if (response.data.code == '701') {
              this.pageData.importOnjobLoad = false;
              this.$notify.warning({
                title: this.$t("notify.title_server"),
                message: this.$t('notify.content_ill')
              });
              this.getOnjobUserLocal();
              return;
            } else {
              //code = 700 and more
              this.pageData.importOnjobLoad = false;
              this.$notify.error({
                title: this.$t("notify.title_server"),
                message: this.$t('notify.content_refused')
              });
              return;
            }
          } else {
            this.pageData.importOnjobLoad = false;
            this.$notify.error({
              title: this.$t('notify.title_server'),
              message: this.$t('notify.content_server') + response.status
            });
            return;
          }
        }).catch((error) => {
          this.$notify.error({
            title: this.$t("notify.title_server"),
            message: this.$t('notify.content_refused')
          });
          return;
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t("text.text_canceled")
        });
      });
    },
    getStaff() {
      let params = {
        user: {}
      };
      params.user.username = this.pageData.username;

      this.$axios({
        method: 'post',
        url: this.$api + '/api/evaluate/queryEvaluateUsers',
        data: params
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {

            this.pageData.staffList = [];
            this.pageData.staffList = response.data.data;

            return;
          } else if (response.data.code == '210') {
            this.$notify.warning({
              title: this.$t("notify.title_server"),
              message: this.$t('staff.notify_staffnull')
            });
            return;
          } else {
            //code = 700 and more
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    },
    getOnjobUserLocal() {
      this.$axios({
        method: 'post',
        url: this.$api + '/api/ex/queryOnjobUser'
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {
            this.pageData.localOnjobUserList = [];
            this.pageData.localOnjobUserList = response.data.data;

            return;
          } else if (response.data.code == '210') {
            this.$notify.warning({
              title: this.$t("notify.title_notification"),
              message: this.$t('staff.notify_no_onjobuser')
            });
            return;
          } else {
            //code = 700 and more
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    },
    openStaffReviser(staff) {
      this.pageData.staffReviserVisible = true;
      this.formData.modifyStaff.fullname = staff.fullname;
      this.formData.modifyStaff.dept_id = staff.dept_id;
      this.formData.modifyStaff.username = staff.username;
      this.formData.modifyStaff.dingtalk_userid = staff.dingtalk_userid;
    },
    openStaffDeleter(staff) {
      this.$confirm(this.$t("staff.text_delete_staff_body"), this.$t("text.text_confirm_title"), {
        confirmButtonText: this.$t("common.btn_message_confirm"),
        cancelButtonText: this.$t("common.btn_message_cancel"),
        type: 'warning'
      }).then(() => {
        this.$axios({
          method: 'post',
          url: this.$api + '/api/evaluate/removeStaffByUsername',
          data: staff
        }).then((response) => {
          if (response.status == 200) {
            if (response.data.code == '200') {
              this.$ElMessage.success({
                message: this.$t("text.message_saved"),
                type: 'success'
              });

              this.getStaff();

              return;
            } else {
              //code = 700 and more

              this.pageData.doModifyStaffLoading = true;

              this.$notify.error({
                title: this.$t("notify.title_server"),
                message: this.$t('notify.content_refused')
              });
              return;
            }
          } else {
            this.pageData.doModifyStaffLoading = true;
            this.$notify.error({
              title: this.$t('notify.title_server'),
              message: this.$t('notify.content_server') + response.status
            });
            return;
          }
        }).catch((error) => {
          this.pageData.doModifyStaffLoading = true;
          this.$notify.error({
            title: this.$t("notify.title_server"),
            message: this.$t('notify.content_refused')
          });
          return;
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t("text.text_canceled")
        });
      });
    },
    doModifyStaff(staff) {
      this.pageData.doModifyStaffLoading = true;

      this.$axios({
        method: 'post',
        url: this.$api + '/api/evaluate/updateStaffInfo',
        data: staff
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {
            this.$ElMessage.success({
              message: this.$t("text.message_saved"),
              type: 'success'
            });

            this.pageData.doModifyStaffLoading = false;
            this.pageData.staffReviserVisible = false;
            this.getStaff();

            return;
          } else {
            //code = 700 and more

            this.pageData.doModifyStaffLoading = true;

            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.pageData.doModifyStaffLoading = true;
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.pageData.doModifyStaffLoading = true;
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    }
  }
}
</script>

<style scoped lang="scss">
.staff_content {
  margin-top: 10px;
  padding: 20px;
  overflow: auto;
  height: 100vh;
}
.staff_content::-webkit-scrollbar {
  width: 0;
}
.staff_top_content .el-button {
  margin-bottom: 20px;
  margin-right: 10px;
}
.staff_top_content .el-alert {
  margin-bottom: 20px;
}
.top_btn_content {
  float: right;
}
.countStaff {
  float: left;
  margin-left: 20px;
  font-size: 14px;
  color: #f56c6c;
}
.addStaffBtn {
  margin-top: -30px;
  float: right;
}
</style>